import React, { Component } from "react";
import TopNav from "components/Navbars/Navbar";
import { Container, Row, Col, Card, CardBody, CardText } from "reactstrap";
import Footer from "components/Footers/Footer.js";

class About extends Component {
  render() {
    return (
      <>
        <TopNav />

        <div className="main">
          <div className="page-header">
            <div className="filter" />
            <Container>
              <Row>
                <Col>
                  <div className="motto text-center">
                    <h1>Бидний тухай</h1>
                    <h3>
                      Cash One аппликэйшн нь ухаалаг гар утсанд суурилсан цахим
                      зээлийн үйлчилгээг хэрэглэгчдэд санал болгох бөгөөд 50,000
                      - 5,000,000 төгрөгийг, 60 болон түүнээс дээш хоногийн
                      хугацаанд барьцаагүйгээр олгох юм. Энэхүү зээлийн
                      үйлчилгээний онцлог нь хэрэглэгч санхүүгийн гэнэтийн
                      хэрэгцээ тулгарсан үед хэн нэгнээс тусламж эрж эсвэл аль
                      нэг санхүүгийн байгууллагын дараалалд зогсож цаг заваа
                      үрэхгүйгээр, зөвхөн өөрийн ухаалаг утсыг ашиглан асуудлаа
                      шуурхай шийдэх боломжтой болох билээ.
                    </h3>
                    <br />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <div>
                    <h2 className="title">Танилцуулга</h2>
                  </div>
                  <Card style={{ minHeight: "150px" }}>
                    <CardBody>
                      <CardText>
                        Кеш Ван” зээлийн апликейшн НЬ 2019 ОНД МОНГОЛ УЛСЫН
                        ЖИЖИГ, ДУНД БИЗНЕСИЙН САНХҮҮЖИЛТИЙГ ДЭМЖИХ ЗОРИЛГОТОЙ
                        БАЙГУУЛАГДСАН.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                  <div>
                    <h2 className="title">Эрхэм зорилго</h2>
                  </div>
                  <Card style={{ minHeight: "150px" }}>
                    <CardBody>
                      <CardText>
                        ХЭРЭГЛЭГЧИЙНХЭЭ ИТГЭЛИЙГ ХҮЛЭЭСЭН НАЙДВАРТАЙ ТҮНШ
                        БАЙГУУЛЛАГА БАЙНА.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default About;
