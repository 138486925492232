import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import HomePage from "views/pages/HomePage";
import Contact from "views/pages/Contact";
import RegisterPage from "views/pages/RegisterPage.js";
import Condition from "views/pages/Condition";
import Faq from "views/pages/Faq";
import About from "views/pages/About";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route path="/home" render={(props) => <HomePage {...props} />} />
      <Route path="/contact" render={(props) => <Contact {...props} />} />
      <Route path="/about" render={(props) => <About {...props} />} />
      <Route
        path="/register-page"
        render={(props) => <RegisterPage {...props} />}
      />
      <Route path="/help" render={(props) => <Faq {...props} />} />
      <Route
        path="/privacy-policy"
        render={(props) => <Condition {...props} />}
      />
      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
