import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

import mobile from "assets/mobile.png";
import circle from "assets/circle.png";
import home_Card from "assets/home_card.png";
import bag from "assets/bag.png";
import cup from "assets/cup.png";

// core components

function LandingPageHeader() {
  let pageHeaderRef = React.createRef();
  let mobileRef = React.createRef();
  let bagRef = React.createRef();
  let circleRef = React.createRef();
  let cupRef = React.createRef();
  let cardRef = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth < 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeaderRef.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  const startAnimation = (e) => {
    let xAxis = (window.innerWidth / 2 - e.pageX) / 20;
    let yAxis = (window.innerHeight / 2 - e.pageY) / 20;

    let xAxis1 = (window.innerWidth / 2 - e.pageX) / 45;
    let yAxis1 = (window.innerHeight / 2 - e.pageY) / 45;

    mobileRef.current.style.transform = `translate3d(${xAxis1}px, ${yAxis1}px, 0) `;
    circleRef.current.style.transform = `translate3d(${xAxis}px,${yAxis}px, 0 ) `;
    cupRef.current.style.transform = `translate3d(${xAxis}px, ${yAxis}px, 0) `;
    bagRef.current.style.transform = `translate3d(${xAxis}px, ${yAxis}px, 0) `;
    cardRef.current.style.transform = `translate3d(${xAxis}px, ${yAxis}px, 0) `;
  };

  return (
    <>
      <div
        ref={pageHeaderRef}
        className="page-header"
        onMouseMove={startAnimation}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="header-text">
              <div className="motto">
                <h1 className="cashone-title">Cash one</h1>
                <h3 className="cashone-h3">
                  Санхүүгийн үйлчилгээний шинэ цахим хэрэглүүр Cash One
                </h3>
                <br />
                <Button
                  href="https://play.google.com/store/apps/details?id=mn.gobifinance.cashone"
                  className="btn-round mr-1"
                  color="neutral"
                  target="_blank"
                  outline
                >
                  <i class="fa fa-android"></i>
                  Android
                </Button>
                <Button
                  href="https://apps.apple.com/mn/app/cashone/id1515473531"
                  className="btn-round"
                  color="neutral"
                  type="button"
                  outline
                  target="_blank"
                >
                  <i class="fa fa-apple"></i>
                  IOS
                </Button>
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="mobile-container">
                <img
                  ref={mobileRef}
                  className="mobile"
                  style={{
                    position: "absolute",
                  }}
                  src={mobile}
                  alt=""
                ></img>
                <img
                  ref={circleRef}
                  className="circle-mobile"
                  style={{
                    zIndex: "4",

                    position: "absolute",
                  }}
                  src={circle}
                  alt=""
                ></img>
                <img
                  ref={cupRef}
                  className="cup"
                  style={{
                    zIndex: "6",

                    position: "absolute",
                  }}
                  src={cup}
                  alt=""
                ></img>
                <img
                  ref={bagRef}
                  className="bag"
                  style={{
                    zIndex: "4",

                    position: "absolute",
                  }}
                  src={bag}
                  alt=""
                ></img>
                <img
                  ref={cardRef}
                  className="home_card"
                  style={{
                    zIndex: "6",

                    position: "absolute",
                  }}
                  src={home_Card}
                  alt=""
                ></img>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  paddingBottom: "50px",
                }}
                className="motto mobile-motto"
              >
                <h1 className="cashone-title">Cash one</h1>
                <h3 className="cashone-h3">
                  Санхүүгийн үйлчилгээний шинэ цахим хэрэглүүр Cash One
                </h3>
                <br />
                <Button
                  href="#"
                  className="btn-round mr-1"
                  color="neutral"
                  target="_blank"
                  outline
                >
                  <i class="fa fa-android"></i>
                  Android
                </Button>
                <Button
                  href="#"
                  className="btn-round"
                  color="neutral"
                  type="button"
                  outline
                >
                  <i class="fa fa-apple"></i>
                  IOS
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
