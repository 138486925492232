import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import TopNav from "components/Navbars/Navbar";

import Footer from "components/Footers/Footer.js";

function Condition() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const [mn, changeLang] = useState(true);
  const [name, changeName] = useState("English");

  const ChangeLang = () => {
    changeLang(!mn);
    if (name === "Монгол") {
      changeName("English");
    } else {
      changeName("Монгол");
    }
  };

  return (
    <>
      <TopNav />

      <div className="main">
        <div className="section text-center">
          <div
            style={{
              cursor: "pointer",
              marginTop: "20px",
              marginRight: "20px",
              textAlign: "right",
              fontWeight: "bold",
              color: "#7f22ca",
            }}
            onClick={ChangeLang}
          >
            {name}
          </div>
          {mn ? (
            <Container>
              <Row>
                <div>
                  <h2 style={{ marginBottom: "20px" }}>Үйлчилгээний нөхцөл</h2>

                  <p
                    style={{
                      padding: "20px",
                      marginBottom: "40px",
                      textAlign: "justify",
                    }}
                  >
                    Энэхүү үйлчилгээний нөхцөл нь “Говь Финанс ББСБ” (цаашид ББСБ гэх)-аас 
                    Кэш Ван /Cash One/ аппликейшнээр дамжуулан санал болгох зээлийн бүтээгдэхүүн, 
                    бусад санхүүгийн үйлчилгээг авахад Хэрэглэгчийн зүгээс шаардагдах 
                    Хэрэглэгчийн хувийн мэдээллийг хүлээн авах, хадгалах, уг 
                    нөхцөлд заасан зорилгоор ашиглах, боловсруулалт хийх эрхийг олгох эрх зүйн үр 
                    дагавар бүхий баримт бичиг тул сайтар танилцаж, зөвшөөрөх эсэхээ шийдэхийг зөвлөж байна.
                  </p>
                  <p>
                    Хэрэглэгч энэ мөчөөс эхлэн “Кэш Ван” аппликейшнийг үргэлжлүүлэн ашиглавал, уг үйлчилгээний 
                    нөхцөлийг бүрэн хүлээн зөвшөөрч, баталгаажуулсанд тооцно.
                  </p>
                  <p>
                    Хэрэв та энэхүү үйлчилгээний нөхцөлийг хүлээн зөвшөөрөхгүй тохиолдолд, “Кэш Ван” аппликейшнийг 
                    үргэлжлүүлэн ашиглахгүй буюу өөрийн гар утаснаас устгахыг зөвлөж байна.
                  </p>
                </div>
              </Row>
              <Row>
                {/* <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                > */}
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-alert-circle-i"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Мэдээлэл цуглуулалт, боловсруулалт ба дамжуулалт
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "10px" }}
                    >
                      <p>
                        Хэрэглэгч энэхүү үйлчилгээний нөхцлийн хүрээнд, дараах 
                        зөвшөөрөл, эрхийг “Кэш Ван” аппликейшнд олгоно. Үүнд:{" "}
                      </p>
                      <p>
                        1. Хэрэглэгчээс авах 1.1 -д дурдсан мэдээллүүдийг бид харилцагчийн Зээлжих оноог 
                        /Credit Score/ тогтоох буюу хэдий хэмжээний зээлийг ямар нөхцлөөр авах боломжтой эсэхийг тодорхойлох, гэрээний 
                        төсөл боловсруулах, бүтээгдэхүүн үйлчилгээгээ хэрэглэгчид хүртээмжтэй хялбар болгох зорилгоор зөвхөн дотоодын хэрэгцээнд 
                        зориулан ашиглах эрхийг хэрэглэгч “Говь Финанс ББСБ” ХХК-д олгох, “Говь Финанс ББСБ” ХХК нь дээр дурдсанаас өөр зорилгоор гуравдагч 
                        этгээдэд задруулахгүй байх үүргийг энэхүү үйлчилгээний нөхцлөөр хүлээн зөвшөөрч байгаа болно. Үүнд:{" "}
                        <li>
                          Бүртгэлийн мэдээлэл /гар утасны дугаар, овог, нэр, регистрийн дугаар / хэрэглэгч өөрөө бөглөх
                        </li>
                        <li>
                          Хэрэглэгчийн хурууны хэвийг болон цахим үнэмлэхний баталгаажуулах зургийг нэг удаагийн гэрээ байгуулахдаа тусгай төхөөрөмжөөр 
                          дамжуулан таны улсын бүртгэлд бүртгэлтэй мэдээллийг харах, хадгалах, хэрэглэгчийг мөн эсэхийг баталгаажуулах, зээлжих оноог тодорхойлоход ашиглах
                        </li>
                        <li>
                          Нийгмийн даатгалын тодорхойлолт буюу сүүлийн 6-н сарын нийгмийн даатгал төлөлтийн мэдээллийг зээлжих оноог 
                          тогтооход ашиглах, хэрэглэгч өөрөө тодорхойлолтыг ТҮЦ машинаас авах ба манай аппликешн-д QR кодийг уншуулах
                        </li>
                        <li>
                          Зээлийн мэдээллийн сангаас зээлийн түөүхийн мэдээллийг зээлжих оноог тодорхойлоход ашиглах, зөвшөөрөгдсөн эрхийн дагуу манай систем шалгах
                        </li>
                        <li>
                          Гар утасны мэдээлэл тэр дундаа утасны жагсаалт, байршил, үйлдвэрлэгчийн мэдээллийг хэрэглэгчийн зөвшөөрлөөр түүний зээлжих оноог тодорхойлоход 
                          ашиглах ба Кредолаб компанид илгээх, хадгалах, хиймэл оюун ухаан ашиглах аргаар таны зээлжих оноог тодорхойлох эрхийг энэхүү үйлчилгээний 
                          нөхцлөөр “Говь Финанс ББСБ” ХХК-д олгохыг харилцагч би зөвшөөрч байгаа болно.
                        </li>
                        <li>
                          Утасны жагсаалт, календарь, бүртгэлтэй цахим хаяг болон сошиал хаяг, утасны багтаамжийн мэдээлэл, хэрэглэж буй бусад аппликейшн зэрэгт нэвтрэх 
                          боломжийг олгосноор бид таны зээлийн үнэлгээг илүү сайн үнэлэх мэдээлэллийг цуглуулах юм. Энэхүү мэдээллийг зөвхөн нэг удаа метадата хэлбэрээр 
                          цуглуулж авах ба зөвхөн кредит үнэлгээг оновчтой тооцоолохын тулд Кредолаб-тай хуваалцаж ашиглагдана
                        </li>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        {" "}
                          2. “Кэш Ван” аппликейшнаар дамжуулан таны гар утаснаас авсан мэдээлийг боловсруулж эрсдэлийн 
                          анализ хийж байгаа бөгөөд уг программ нь хэрэглэгчээс дараах шаардлагатай мэдээллүүдийг 
                          аппликейшнаар дамжуулан илгээхийг шаарддаг. Таны аюулгүй байдлыг хангахын тулд таны хувийн 
                          мэдээллийг устган тусгай кодоор оруулдаг (хуурамж нэршилээр нэрлэдэг). Тусгай кодоор оруулсан 
                          мета дата нь анхны хувилбарыг дахин  боловсруулах боломжгүй болгодог. Энэхүү мэдээллийг шийдвэр 
                          гаргах үйл явцын нэг хэсэг болгох бөгөөд таны зээлжих эрхийг үнэлэхэд ашигладаг. Мөн бид алгоритм 
                          болон математик загварчлалаар дамжуулан санхүүгийн үйлчилгээ авах таны хүсэлийг үнэлэхийн тулд 
                          таны хувийн мэдээллийг ашиглах боломжтой болно.
                      </p>
                      <p>
                        3. Хэрэглэгчидээс цуглуулсан мэдээллийг эрх бүхий байгууллагад тухайн хэрэглэгчидийг таних, бүтээгдэхүүнийг сайжруулах
                        зохилгоор хуваалцдаг ба хэрэглэгчидийн хувийн мэдээллийг бусдад задруулахгүй байх үүрэг хүлээнэ. “CredoLab” гар утасны 
                        технологийн тайлбарыг доор оруулав.

                        CredoLab Pte Ltd: "CashOne" аппликейшн нь хэрэглэгчийн зээлийн үнэлгээнд зэрэглэл тогтоох зорилгоор “CredoLab”-н үйлчилгээг 
                        ашигладаг. “CredoLab”-ийн нууцлалын бодлогыг дараах холбоосоор үзэх боломжтой. 
                        https://www.credolab.com/privacy-policies/gdpr-privacy-policy 
                      </p>
                      <p>
                      4. Та НДШ төлдөг бол сүүлийн 6 сарын нийгмийн даатгалын лавлагаагаар НДШ төлдөггүй бизнес орлоготой бол сүүлийн 6 сарын 
                      дансны хуулгаараа орлогоо баталгаажуулж зээлжих эрхээ тогтоолгох боломжтой.Таны орлого баталгаажуулалтаас хамаарч 
                      зээлийн эрх хэлбэлзэх магадлалтай. Зээлийн нөхцөл:
                      </p>
                      <p>
                        5. Хэрэглэгчийн оруулсан, дамжуулсан хувийн болон санхүүгийн мэдээлэлд, тусгай аргачлалын тусламжтайгаар 
                        дүн шинжилгээ хийх, тэдгээрийн үнэн зөвийг шалгаж баталгаажуулах
                      </p>

                      <p>
                        6. Хэрэглэгчийн оруулсан, дамжуулсан хувийн болон санхүүгийн мэдээллийг, авах боломжтой зээлийн хэмжээ, 
                        нөхцөлийг тодорхойлох, үйлчилгээ үзүүлэх болсон тохиолдолд байгуулах гэрээний төслийг боловсруулахад ашиглах
                      </p>
                      <p>
                        7. Зээлийн зарим бүтээгдэхүүнтэй холбоотойгоор
                        Хэрэглэгчийн интернет банкны нэвтрэх мэдээллийг авсан
                        тохиолдолд, түүнийг ашиглан Хэрэглэгчийн интернет банк
                        руу нэг удаа нэвтэрч, банкны дансны хуулгыг автоматаар
                        хуулбарлан авч хадгалах, ийнхүү авсан мэдээллийг энэхүү
                        үйлчилгээний нөхцөлд заасан зорилгоор ашиглах
                      </p>
                      <p>
                        8. ББСБ нь энэхүү үйлчилгээний нөхцөлд заасны дагуу
                        цуглуулсан мэдээллийг үйлчилгээний чанар, хүртээмжийг
                        сайжруулах, шинэ бүтээгдэхүүн гаргахтай холбоотойгоор
                        дотоод хэрэгцээнд ашиглах
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-lock-circle-open"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Аппликейшн ашиглалт
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        1. Хэрэглэгч өөрийн мэдээллийг хуурамчаар оруулахгүй байх. Хэрэв хэрэглэгч санаатайгаар 
                        хуурамч мэдээлэл оруулсан тохиолдолд манай компани үйлчилгээ үзүүлэхээс татгалзах эрхтэй 
                        ба үүнтэй холбоотой аливаа хохирол үүссэн тохиолдолд “Говь Финанс ББСБ” аливаа үүрэг хариуцлагыг хүлээхгүй болно.
                      </p>
                      <p>
                        2. Хэрэглэгчийн нэвтрэх нэр, нууц үг алдагдсантай холбоотой үүсэх хариуцлагыг Хэрэглэгч хариуцна.
                      </p>
                      <p>
                        3. Хэрэглэгч өөрийн бүртгэл, зээл, аливаа өөр дээр нь үүссэн дансыг бусдад шилжүүлэхгүй, 
                        худалдахгүй байх үүрэгтэй ба хэрэв ийнхүү худалдан борлуулсан, шилжүүлсэн үйлдэл нь илэрсэн 
                        тохиолдолд тухайн данс болон бүртгэлийг хаах ба үүнтэй холбогдсон аливаа хохирлыг “Говь Финанс ББСБ” ХХК хариуцахгүй болно.
                      </p>
                      <p>
                        4. Хэрэглэгч нь аппликешнийг хар тамхи, хуульд өөрөөр заагаагүй бол мансууруулах бодис үйлдвэрлэх, 
                        импортлох худалдах, садар самуун явдлыг зохион байгуулах, түүнийг аливаа хэлбэрээр сурталчилах, дэмжих, 
                        казиногийн үйл ажиллагааг дэмжих, мөнгө угаах, залилан мэхлэх, сүрдүүлэг хийх, өөрийн эрх мэдлийг урвуулан ашиглах, 
                        мэдээлэл барьцаалах, терроризмыг санхүүжүүлэх болон хүчирхийллийн бусад үйл ажиллагаанд зориулан ашиглахыг хориглоно.
                      </p>
                      <p>
                        5. Давагдашгүй хүчин зүйл гэж урьдаас таамаглаагүй, мэдээгүй бөгөөд түүнийг шийдвэрлэхэд нэн бэрхшээлтэй 
                        нөхцөл байдлыг ойлгож үзнэ. Үүнд Хакерийн халдлага, эрх бүхий байгууллагын технологийн тохируулга, харилцагч 
                        компаниудын системийн доголдолоос шалтгаалан аппликешн түр хугацаагаар тасалдах боломжтой. 
                        Харилцагч нь эдгээр шалтгаанаас үүргээ биелүүлэхэд учирсан саадтай холбоотойгоор хохирол шаардах эрхгүй болно. 
                        Системийн сайжруулалттай холбоотой урьдчилан мэдэгдсэнээр үйлчилгээг түр зогсоож болно.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-lock-circle-open"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Шинэчлэл болон нэмэлт өөрчлөлт
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        1. ББСБ нь үйлчилгээний чанарыг сайжруулах зорилгоор “Кэш Ван” 
                        аппликейшнд програм хангамжийн шинэчлэл, нэмэлт өөрчлөлт хийж болно.
                      </p>
                      <p>
                        2. “Кэш Ван” аппликейшнд шинэчлэл, нэмэлт өөрчлөлт орсноор энэхүү үйлчилгээний 
                        нөхцөл давхар шинэчлэгдэж болно. Хэрэглэгч “Кэш Ван” аппликейшний шинэчлэгдсэн 
                        хувилбарыг татаж суулган, ашигласнаар мөн шинэчлэгдсэн үйлчилгээний нөхцөлийг хүлээн зөвшөөрсөнд тооцно.
                      </p>
                      <p>
                        3. Хэрэглэгч “Кэш Ван” аппликейшний шинэчлэл, нэмэлт өөрчлөлт хийгдсэн хувилбарыг өөрийн гар утсанд 
                        заавал татаж суулгах үүргийг хүлээхгүй. Ингэхдээ хуучин хувилбарыг үргэлжлүүлэн ашиглах нь “Кэш Ван” 
                        аппликейшнээр дамжуулан үйлчилгээ авах боломжгүй болж болно.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                {/* </Col> */}
                {/* <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                > */}
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-hat-3"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Мэдэгдэл ба мэдээлэл хүргэх
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        Хэрэглэгч энэхүү үйлчилгээний нөхцлийг хүлээн
                        зөвшөөрснөөр ББСБ-аас санал болгох зээлийн бүтээгдэхүүн,
                        бусад санхүүгийн үйлчилгээтэй холбоотой мэдээлэл, түүнд
                        орсон өөрчлөлт зэргийг “Кэш Ван” аппликейшнээр дамжуулан
                        хүлээн авч, танилцаж, баталгаажуулахыг хүлээн
                        зөвшөөрсөнд тооцно.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-support-17"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Мэдээлэл нууцлал
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        {" "}
                        1. ББСБ нь Хэрэглэгчийн оруулсан, дамжуулсан хувийн болон санхүүгийн мэдээллийг энэхүү 
                        үйлчилгээний нөхцөлд зааснаас бусад зорилгоор ашиглах ба бусдад задруулахгүй,мэдээллийн 
                        нууцлалыг хамгаалах үүрэг хүлээнэ.
                      </p>
                      <p>
                        2. Хэрэглэгчийн гар утасны мэдээллийг зөвхөн нууцлагдсан форматаар боловсруулалт хийж, 
                        дамжуулах ба Хэрэглэгчийн гар утаснаас ямар нэгэн хувийн мэдээллийг дамжуулж авахгүй болно.
                      </p>
                      <p style={{ textIndent: "20px" }}>
                        4. “Говь Финанс ББСБ” ХХК дараах тохиолдолд урьдчилан
                        сануулалгүйгээр зээлийн гэрээг дуусгавар болгоно.
                        <li>Гэрээгээр хүлээсэн үүргээ удаа дараа зөрчсөн.</li>
                        <li>
                          Бусдын дансыг зөвшөөрөлгүйгээр үйл ажиллагаанд
                          ашигласан.
                        </li>
                        <li>Худал мэдээлэл өгч хууран мэхэлсэн.</li>
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-support-17"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Хязгаарлалт
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>1. 18 нас хүрсэн Монгол улсын иргэн байх</p>
                      <p>2. Ухаалаг гар утастай байх</p>
                      <p>
                        3. Хэрэглэгч энэхүү үйлчилгээний нөхцөлтэй уншиж
                        танилцалгүй “Кэш Ван” аппликэшнийг ашиглан дээр дурдсан
                        нөхцлүүдийн улмаас гэрээгээ цуцлуулж, хохирол амссан
                        тохилдолд “Говь Финанс ББСБ” хариуцлага хүлээхгүй ба
                        учирсан хохиролд хэрэглэгч бүрэн хариуцлага хүлээнэ.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <div>
                  <h2 style={{ marginBottom: "20px" }}>Privacy Policy</h2>

                  <p
                    style={{
                      padding: "20px",
                      marginBottom: "40px",
                      textAlign: "justify",
                    }}
                  >
                    These privacy policies are the legal document that authorizes to receive, store, use for purposes described in 
                    the terms and process Customer’s personal information required for receiving loan products and other financial 
                    services offered by “Gobi Finance NBFI” LLC (hereinafter referred to as "NBFI") through "Cash One" app. Hence, 
                    we recommend you carefully read and decide whether or not to accept. If Customer chooses to continue to use the 
                    "Cash One" app from this moment, he/she will be deemed to have accepted and agreed to the terms of service.
                    If you disagree with these terms of service, we recommend you discontinue use of the "Cash One" app or delete 
                    it from your mobile phone.
                  </p>
                </div>
              </Row>
              <Row>
                {/* <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              > */}
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-alert-circle-i"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Information gathering, processing and transmission
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                      Customer shall grant the following authorization and right to 
                      "Cash One" app within the scope of these terms of service: <br></br>
                      </p>
                      <p>
                        1. We, "Gobi Finance NBFI" LLC will only use information provided in 1.1 for internal purposes only 
                        in order to determine the Credit Score of the customer, or to determine loan amount, or to draft its 
                        loan agreement, and to make its products and services accessible to its customers. 
                        "Gobi Finance NBFI" LLC will be responsibly not to disclose Customer's information for any 
                        purpose other than the above terms.
                        <li>
                          Registration information /mobile phone number, full name, state registration number/, will be provided by customer.
                        </li>
                        <li>
                          Fingerprint biometric information, identification card’s proof of photo, to verify, identify, save information of registration and to calculate credit score.
                        </li>
                        <li>
                          Social insurance certificate and its payment information of last 6 months, customer shall receive statement from State "Kiosk machine" and scan QR code on Cash One application.
                        </li>
                        <li>
                          Credit history information, we will use Credit Information Database information to calculate credit score and customer shall have the right to use our platform in accordance with authorized right and credit limitation.
                        </li>
                        <li>
                          Mobile phone information, including contact list, location, and manufacturer information. Under this service, I agree to grant "Gobi Finance NBFI" LLC the right to use above information in order to calculate credit score by sending to CredoLab Pte. Ltd, storing, using for artificial intelligence and to fight against money laundering/combating terrorist financing crimes.
                        </li>
                        <li>
                        By enabling access to Contacts, Calendar, Registered Accounts which might include Social Accounts, Storage Information, (Only if correspondent module is used) and Installed Applications, we will collect data to better evaluate your loan application. This information is only accessed once, collected in the form of metadata, is shared and used by Credolab only to calculate a more accurate credit score.
                        </li>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        2. We collect users’ data from their phone to provide a segmented risk profile and generate an 
                        aggregated statistical information via “Cash One” app, the application will ask permissions from 
                        the users to transmit the following types of information within the application. To protect your 
                        identity, we remove all personal identifiers (if any) and pseudonymise such information. 
                        Any metadata processed in this way cannot be used to reproduce the original data collected. 
                        We use the data as part of our decision process to assess your creditworthiness for a loan 
                        product with us. We may also use your data to assess your interest in receiving financial 
                        services through algorithms and mathematical modelling.
                      </p>
                      <p>
                        3. The data collected is shared with selected and authorized providers of data analyst services. 
                        The service providers are described below:
                        <br></br>
                        CredoLab Pte Ltd: “CashOne” application uses CredoLab service to access user’s credit worthiness.
                        CredoLab’s privacy policy is available at the following link:
                        https://www.credolab.com/privacy-policies/gdpr-privacy-policy
                      </p>
                      <p>
                        4. (We ask customers’ social insurance certificate and its payment information of last 6 months, 
                        if the customer has a business income that has not been paid for social insurance, the customers 
                        can use the last 6 months account statement. Depending on the customer’s income guarantee, loan rights may vary)
                      </p>
                      <p>
                        5. Analyze Customer’s personal and financial information
                        submitted and transmitted with the support of special
                        method and check and confirm their accuracy.
                      </p>
                      <p>
                        6. To use Customer’s personal and financial information submitted and transmitted to draft contract to execute, 
                        in the event of determination of loan amount and terms and rendering of service.
                      </p>
                      <p>
                        7. If obtained Customer’s online banking information in connection with number of loan products, to access 
                        Customer’s online banking for once using it, and automatically copy and keep his/her bank statement and use 
                        the information obtained so for purposes set out in these terms of service.
                      </p>
                      <p>
                        8. To authorize NBFI to use the information gathered pursuant to these terms of service for internal use 
                        only in relation to improving the quality and availability of service and launching new products;
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-lock-circle-open"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Application Usage
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        1. “Gobi Finance NBFI” LLC will not be responsible for any damage caused by the customer's 
                        accidental actions on the application, will not be responsible to pay, or cover such damages. 
                        Our company reserves the right to refuse to provide services if the customer intentionally 
                        provides false information, and “Gobi Finance NBFI” will not be liable in case of any damages.
                      </p>
                      <p>
                        2. Customer will be responsible for any damages of losing his/her own login information.
                      </p>
                      <p>
                        3. Customer is responsible not to transfer, or sell his/her registration, account, or loan. 
                        If such sale, or transfer is confirmed, your account and registration will be terminated, 
                        and "Gobi Finance NBFI" LLC will not be liable/responsible for any damages.
                      </p>
                      <p>
                        4. Unless otherwise provided by law, the application shall not be used for the production, 
                        import and sale of narcotics, organization of pornography, or promotion of pornography 
                        in any form, promotion of casino activities, money laundering and fraud. "Gobi Finance NBFI" 
                        will strictly prohibit customers to use this application for above mentioned purposes.
                      </p>
                      <p>
                        5. Force majeure is defined as a situation that is unpredictable or uncertain and is very 
                        difficult to resolve. These include hacker attacks, technological adjustments by the 
                        authorities, and system crashes by client companies that can temporarily disrupt an application.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                {/* </Col> */}
                {/* <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              > */}
              <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-hat-3"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Update and upgrade
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        1. NBFI may update or upgrade the "Cash One" app to improve the quality of service.
                      </p>
                      <p>
                        2. Once the "Cash One" app is updated or upgraded, these terms of service may be updated as well. 
                        If Customer downloads, installs and uses an updated version of "Cash One" app, he/she will be deemed to 
                        have accepted the updated terms of service as well.
                      </p>
                      <p>
                        3. Customer is not obligated to download and install an updated and upgraded "Cash One" app on his/her 
                        mobile phone. However, continuous use of the old version may prevent users from receiving service through 
                        the "Cash One" app.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-hat-3"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Notice and information delivery
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                       By accepting these terms of service, customers will be deemed to have agreed to receive and learn about 
                       information on loan products and other financial services offered by NBFI and approve relevant updates 
                       through the "Cash One" app.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-support-17"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Security and Trust
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        {" "}
                        1. NBFI shall not use Customer’s personal and financial information that is submitted or transferred, 
                        for purposes other than purposes set forth in these terms of service and not shared with others and 
                        maintain its confidentiality.
                      </p>
                      <p>
                        2. Customer’s mobile phone information will be processed and transmitted only by encoding format and any 
                        personal information from Customers mobile phone will not be obtained through transmission.
                      </p>
                      <p style={{ textIndent: "20px" }}>
                        4. Gobi Finance NBFI will terminate the loan contract
                        without prior notice in the following cases.
                        <li>Repeated breaches of contract.</li>
                        <li>
                          Unauthorized use of other people's accounts.
                        </li>
                        <li>
                          Giving false information
                        </li>
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
                <Card style={{ minHeight: "400px" }}>
                  <CardBody>
                    <i
                      style={{
                        fontSize: "35px",
                        color: "#7829c9",
                        fontWeight: "500",
                      }}
                      className="nc-icon nc-support-17"
                    />
                    <CardTitle
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      Restriction
                    </CardTitle>
                    <CardText
                      style={{ textAlign: "justify", textIndent: "20px" }}
                    >
                      <p>
                        1. Be a citizen of Mongolia who is over 18 years old{" "}
                      </p>
                      <p>2. Have a smart phone</p>
                      <p>
                        3. “Gobi Finance NBFI” shall not be responsible for any damages caused by the termination of the 
                        contract and damages caused by the above conditions using the “Cash One” application without the 
                        customer reading the privacy policy of this service.
                      </p>
                    </CardText>
                  </CardBody>
                </Card>
              </Row>
            </Container>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Condition;
