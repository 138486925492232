import React from "react";
import { Container, Row, Col } from "reactstrap";

import tablet from "assets/tablet.png";

// core components
import TopNav from "components/Navbars/IndexNavbar";
import HomePageHeader from "components/Headers/HomePageHeaders.js";
import Footer from "components/Footers/Footer.js";
import "swiper/swiper.scss";
import "./swiper.css";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper";

SwiperCore.use([Pagination, EffectCoverflow, Navigation]);

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("home");
    return function cleanup() {
      document.body.classList.remove("home");
    };
  });

  return (
    <>
      <TopNav />
      <HomePageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col>
                <div className="tablet-container">
                  <img className="tablet-img" src={tablet} alt=""></img>
                  <div className="tablet-text">
                    Та Cashone апликэйшн дээрх хувийн болон санхүүгийн мэдээллээ
                    хурууны хээ, нууц үг болон царай таних технологийн
                    тусламжтай бүрэн аюулгүй хянах боломжтой
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "100px" }}>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Хурдан</h4>
                    <p className="description">
                      24 цаг 7 өдрийн турш Харилцагчийн дансанд зээлийг шуурхай
                      шилжүүлнэ
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Хялбар</h4>
                    <p>
                      Зээл авахын тулд ямар нэгэн барьцаа хөрөнгө шаардахгүй
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Уян хатан</h4>
                    <p>
                      Их тоон өгөгдөлд суурилсан шинжилгээ Иновацлаг зээлийн
                      эрх, хэмжээ тогтоох их тоон өгөгдөлд шинжилгээ хийх загвар
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section  section-dark text-center">
          <div className="card-swiper-container">
            <div className="card-swiper">
              <i className="nc-icon nc-spaceship" />
              <p>Зээлийн хэмжээ 50,000-5,000,000₮</p>
            </div>
            <div className="card-swiper-2">
              <i className="nc-icon nc-bank" />
              <p>Таны санхүүгийн мэдээллээр бид зээлийн хэмжээг тогтоодог</p>
            </div>
            <div className="card-swiper-3">
              <i className="nc-icon nc-delivery-fast" />
              <p>Зээлийн хугацаа 60 болон түүнээс дээш хоног</p>
            </div>
            <div className="card-swiper-4">
              <i className="nc-icon nc-diamond" />
              <p>Та өмнө авсан зээлийн хэмжээгээ ихэсгэх боломжтой</p>
            </div>
            <div className="card-swiper-5">
              <i className="nc-icon nc-istanbul" />
              <p>Барьцаа хөрөнгө шаардахгүй</p>
            </div>
          </div>
          {/* </Row> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
