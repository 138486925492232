import React, { Component } from "react";
import TopNav from "components/Navbars/Navbar";
import { Container, Row, Col } from "reactstrap";
import Footer from "components/Footers/Footer.js";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core";

import sungah1 from "assets/extend.jpg";
import sungah2 from "assets/extend1.jpg";
import toloh from "assets/payment.jpg";
import wallet from "assets/wallet.jpg";
import video from "./../../assets/app.mp4";
import scene from "./../../assets/scene.mp4";
import intro from "assets/introduction.mp4";

const myData = [
  {
    id: "1",
    nameHeader: "Танай апп-ийг яаж татах вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Та хэрэв андройд гар утастай бол Playstore-оос татаж суулгана",
      },
      {
        id: "2",
        name: "Playstore-с олдохгүй тохиолдолд Фэйсбүүкийн чатаар холбогдон татах линк авах боломжтой",
      },
    ],
  },
  {
    id: "2",
    nameHeader: "Яаж бүртгүүлэх вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Та апп-аа татаж суулгаад хувийн мэдээллээрээ аккаунт үүсгэсний дараачаар, анкет бөглөөд, зээл авах боломжит хэмжээ тань өмнөх мэдээлэл дээр үндэслэгдэн тодорхойлогдоно. Үүний дараагаар манай салбар дээр ирж ганцхан удаа гэрээ байгуулаад зээлээ авах эрх үүснэ",
      },
    ],
  },
  {
    id: "3",
    nameHeader: "Зээл яаж авах вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Салбар дээр ирж орлогоо баталгаажуулан гэрээгээ байгуулан./анкет тодорхойлолтоос хамаарч сүүлийн 6 сарын НДШ лавлагаа эсвэл дансны хуулгаар",
      },
      {
        id: "2",
        name: "Та салбар дээр ирж гэрээ байгуулсаны дараачаар “Зээл” хэсгийн зээл авах хэсэгт өөрийн боломжит зээлийн хэмжээнд зээлийн багтаан зээлийн хүсэлтээ илгээснээр зээлээ авна.",
      },
    ],
  },
  {
    id: "4",
    nameHeader: "Дансаа хэрхэн тохируулах вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Апп-ын зээл авах дансаа “Дансны тохиргоо” хэсэгт тохируулна.",
      },
      {
        id: "2",
        name: "Зөвхөн өөрийн дансаа холбон зээлийн хүсэлтээ илгээнэ./Дансны нэр зөрүүтэй болон өөр хүний данс холбосон тохиолдолд дансны мэдээлэл зөрүүтэй гэсэн алдаа заана/",
      },
      {
        id: "3",
        name: "Манайд бүртгэлтэй өөр хүний данс холбосон тохиолдолд/duplicated/ гэсэн алдаа заана.",
      },
    ],
  },
  {
    id: "5",
    nameHeader: "Танай салбар хаана вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Сүхбаатар дүүрэг, 1-р хороо, Чингисийн өргөн чөлөө Гурван гал 4 давхарт 411 тоот (Талбайн урд Гурван гал 4 давхарт 411 тоот)",
      },
    ],
  },
  {
    id: "6",
    nameHeader: "Танайх хэд хүртэл ажилдаг вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Ажлын өдөр бүр 09-18 цаг хүртэл, бямба болон ням гараг бүр 10-17 цаг хүртэл тус тус ажилдаг байгаа.",
      },
    ],
  },

  {
    id: "7",
    nameHeader: "Зээлийн хэмжээгээ хэрхэн өсгөх вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Таны зээлийн боломжит хэмжээ таны зээлийн эргэн төлөлтүүдээ хэр сайн хийсэн эсхээс шалтгаалж үе шаттайгаар нэмэгдэнэ.",
      },
    ],
  },
  {
    id: "8",
    nameHeader: "Зээлийн барьцаа хөрөнгө шаардах уу?",
    subQuestion: [
      {
        id: "1",
        name: "Барьцаагүй зээлийн үйлчилгээ байгаа.",
      },
    ],
  },
  {
    id: "9",
    nameHeader: "Танайх яаж зээл өгдөг юм?",
    subQuestion: [
      {
        id: "1",
        name: "Манай апп танд зээл олгох боломжтой эсэх болон хэр хэмжээний зээл олгох эсхийг тусгай алгоритм дээр үндэслэж тодорхойлдог байгаа.",
      },
    ],
  },
  {
    id: "10",
    nameHeader: "Танай зээлийн хүү хэд вэ?",
    subQuestion: [
      {
        id: "1",
        name: "Манайх зээлийн шимтгэл авдаг байгаа тэр нь таны авахыг хүсч байгаа зээлийн хэмжээ болон зээлийн хугацаа зэргээс хамааран харилцан адилгүй байгаа. Та апп-аараа ороод үзээрэй.",
      },
    ],
  },
];

class Faq extends Component {
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }

  state = {
    settings: [
      { id: "1", open: false },
      { id: "2", open: false },
      { id: "3", open: false },
      { id: "4", open: false },
      { id: "5", open: false },
      { id: "6", open: false },
      { id: "7", open: false },
      { id: "8", open: false },
      { id: "9", open: false },
      { id: "10", open: false },
    ],
  };

  handleClick = (id) => {
    this.setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id ? { ...item, open: !item.open } : item
      ),
    }));
  };

  render() {
    const { settings } = this.state;
    return (
      <>
        <TopNav />
        {/* <HomePageHeader /> */}
        <div className="main">
          <div className="section text-center">
            <Container>
              <h2 style={{ marginBottom: "20px" }}>Түгээмэл асуулт хариулт</h2>
              <Row>
                <Col>
                  <div style={{ marginRight: "15px" }}>
                    <List component="nav">
                      {myData.map((each) => (
                        <React.Fragment key={each.id}>
                          <ListItem
                            button
                            onClick={() => this.handleClick(each.id)}
                          >
                            <ListItemText inset primary={each.nameHeader} />
                            {settings.find((item) => item.id === each.id).open
                              ? "-"
                              : "+"}
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={
                              settings.find((item) => item.id === each.id).open
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {each.subQuestion.map((subData) => (
                                <ListItem key={subData.id} button>
                                  <ListItemText inset primary={subData.name} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      ))}
                    </List>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Зээл сунгах заавар</h2>
                  <div className="help-pic">
                    <img src={sungah2} alt=""></img>
                    <i
                      style={{ fontSize: "5rem", color: "#8217c7" }}
                      className="nc-icon nc-minimal-down"
                    />
                    <img src={sungah1} alt=""></img>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Зээл төлөх заавар</h2>
                  <div className="help-pic">
                    <img src={toloh} alt=""></img>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h2>Хэтэвчээ цэнэглэх заавар</h2>
                  <div className="help-pic">
                    <img src={wallet} alt=""></img>
                  </div>
                </Col>
              </Row>
              <h2 style={{ marginBottom: "20px" }}>Заавар видео</h2>
              <Row>
                <Col>
                  <video
                    className="faq-video1"
                    controls
                    autoPlay={true}
                    // height={400}
                    src={video}
                  ></video>
                </Col>
              </Row>
              <Row>
                <Col>
                  <video
                    className="faq-video"
                    controls
                    // autoPlay={true}
                    // height={400}
                    src={scene}
                  ></video>
                </Col>
              </Row>
              <Row>
                <Col>
                  <video
                    className="faq-video"
                    controls
                    // autoPlay={true}
                    // height={400}
                    src={intro}
                  ></video>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h3 style={{ fontWeight: "600" }}>
                    Таньд ямар нэгэн асуудал тулгарсан бол харилцагч
                    үйлчилгээний төв ажиллаж байна
                  </h3>
                  <div
                    className="phone-time"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      boxSizing: "border-box",
                      height: "80%",
                    }}
                  >
                    <div>
                      <p>
                        Даваа-Баасан <br />
                        09:00-18:00
                      </p>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid black",
                        height: "50%",
                      }}
                    ></div>
                    <div>
                      <p>Утас: 7777-4111</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Faq;
