import React from "react";
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import help from "assets/gurvan_gal.png";

// core components
import TopNav from "components/Navbars/Navbar";

import Footer from "components/Footers/Footer.js";

function Help() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  return (
    <>
      <TopNav />
      {/* <HomePageHeader /> */}
      <div className="main">
        <div className="section landing-section">
          <Container style={{ marginTop: "0px" }}>
            <h2 style={{ marginBottom: "20px" }} className="text-center">
              Холбоо барих
            </h2>
            <Row>
              <Col lg={7}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                >
                  <img
                    style={{ borderRadius: "20px", width: "400px" }}
                    src={help}
                    alt=""
                  ></img>
                </div>
              </Col>
              <Col lg={5}>
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div style={{ fontSize: "16px", padding: "30px" }}>
                    <div>
                      <strong>Хаяг:</strong> Төв номын сангийн автобусны буудлын
                      урд байрлах Гурван гал худалдааны төв 4 давхар 411 тоот{" "}
                    </div>
                    <div>
                      <br /> <strong>Утас:</strong> 7777-4111
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Санал хүсэлт илгээх</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Нэр</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Нэр" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>И-майл</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="И-майл" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Мессеж</label>
                  <Input
                    placeholder="Санал хүсэлтээ илгээнэ үү"
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Илгээх
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Help;
